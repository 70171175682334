
$back: rgb(235,235,235);
$html: rgb(110,110,110);
html{
    color: #000;
    background: $back;
}

nav{
    border-bottom: inset rgb(0,0,0.1) 1px;
    a:hover{
        background-color: lightgrey;
    }
}
.nav{
    justify-content: flex-end;
    @media(min-width:445px){
        margin-bottom: 25px;
    }
}

body{
    background: $back;
}

h5{
    font-weight: bold;
}

.profile{
    float:left; 
    margin-right:20px;
    width: 264px;
    height: 394px;
    @media(min-width: 600px){
        width:150px;
        height: 223.86px;
    }
}

@media(max-width: 480px){
    .about{
        clear: both;
        display: inline-block;
    }
    .profile{
        margin-left: auto;
        margin-right: auto;
        display: block;
        float:none;
    }
}
$animate: all 0.2s ease-in-out;

.project-link > a {
    text-decoration: none !important;
    h4 {
        margin: 0;
        padding: 0;
    }
}
.project-link > a > h4, p > a {
    text-decoration: none !important;
    position: relative;
    
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        left: 0;
        background: #007bff;
        // background: #0056b3;
        visibility: hidden;
        border-radius: 5px;
        transform: scaleX(0);
        transition: .25s linear;
      }
    &:hover::before,
    &:focus::before {
      visibility: visible;
      transform: scaleX(1);
    }
}
footer{
    padding-bottom: 20px;

    a img{
        width: 40px;
        height: 40px;
        float: right;
        transition: ease-out 0.3s;
    
        &:hover{
            filter: contrast(50%);
            -webkit-filter: contrast(50%);
        }
    }
}

code{
    background: darkslategray;
    max-height: 400px;
}

.btn-link{
    font-weight: bold;
    height: 100%;
    width:100%;
    text-align: left;
    padding: 1rem;
}

.foot-text{
    text-align: center;
    vertical-align: middle;
    font-family: italic;
    color:grey;
    margin-top: 8px;
    margin-bottom: 8px;

    @media(max-width:445px){
        margin-top: -4px;
    }
}

/* Specific rule for mobile footer */
@media(max-width:600px){
    footer .container .row .col{
        padding-left: 0px;
        padding-right: 0px;
    }
}



.card-header{
    padding: 0;
}


