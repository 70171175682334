.grain{
  background: url("../static/img/noise.jpg");
  background-size: auto;
  background-size: cover;
  position: absolute;
  width: inherit;
  height: inherit;
  z-index: 99;
  opacity: 0.1;
  pointer-events: none;
}