
.project-card{
  // opacity: 0;
  flex-direction: column;
  p{
    margin-bottom: 0;
  }
  // padding-top: 5px;
  padding-top: 20px;
  margin-top: 30px;
  border-top: solid 1px black

  // animation: fade 2s ease-in 0s 1 normal forwards;
  
  // animation-name: example;
  // animation-duration: 5s;
  // animation-timing-function: linear;
  // animation-delay: 2s;
  // animation-iteration-count: infinite;
  // animation-direction: alternate;
}


// @-webkit-keyframes example {
//   0%   {background-color:red;}
//   25%  {background-color:yellow;}
//   50%  {background-color:blue;}
//   75%  {background-color:green;}
//   100% {background-color:red;}
// }

// @keyframes fade {
//   0%   {
//     // opacity: 0;
//     margin-left: -400px
//   }
//   100% {
//     // opacity: 1;
//     margin-left: 0px
//   }
// }

.project-card, .project-header, .project-main{
  display: inline-flex;
  width: 100%;
}
.project-header{
  display: block;
  @media screen and (min-width: 768px) {
    display: inline-flex;
    flex-wrap: wrap;
  }

  hr{
    width: 100%;
    margin: 1px 0
  }
}


.project-img{
  padding: 0;
}

$img-size: 100px;
.project-img, .project-img img, .project-img iframe.inception{
  width: $img-size;
  height: $img-size;
  @media screen and (min-width: 768px){
    $img-size: 150px;
    width: 150px;
    height: 150px;
  }
}
$t: 4;
$scale: 1 / $t;
$margin: ($t - 1) / 4 * -1em;
iframe.inception {
  pointer-events: none;
  font-size: 200px;
  width: 0.5em * $t !important;
  height: 0.5em * $t !important;
  margin: $margin;
  // margin: (1-$scale) * -1em;
  overflow: hidden;
  z-index: -999;
  transform: scale($scale);
  @media screen and (min-width: 768px){
    font-size: 300px;
  }
}

.project-body{
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  @media screen and (min-width: 768px){
    padding-left: 20px;
  }
}

.project-title{

  // margin-right: auto;
  flex: 1 1 auto;

  h4{
    text-align: left;
    margin-bottom: 0;
  }
  
  .project-job{
    font-style: italic;
    margin-top: -5px;
    font-size: 0.95em;
  }
}


.project-dates{
  font-style: italic;
  vertical-align: bottom;
  margin-bottom: auto;

  @media screen and (min-width: width 768px){
    margin-top: 5px;
  }
}


.project-description, .project-description-mobile{
  
  p{
    font-size: 1em;
    margin-bottom: 0px;
  }
  ul{
    list-style-type: square
  }
}

.project-description{
  @media screen and (max-width: 768px){
    display: none;
  }
}
.project-description-mobile{
  display: none;
  @media screen and (max-width: 768px){
    display: block;
  }
}

.project-footer{
  margin-top: 10px;
  display:inline-flex;

  .project-used{
    width: 100%;
    margin: auto;
    display: inline-flex;
    flex-wrap: wrap;
  }
  .project-link{
    flex: 1 1 auto;

    > a > img {
      transition: ease-out 0.3s;
      &:hover {
        filter: contrast(40%);
        -webkit-filter: contrast(40%);
      }
    }
  }
}

.project-language{
  border-radius: 7px;
  border: solid 1px  #aaa;
  width: fit-content;
  padding: 0 4px;
  font-size: 0.8em;
  margin-right: 5px;
}